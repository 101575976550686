import clsx from "clsx";
import { Container } from "~/components/ui/container";
import type { Cta } from "~/types/index";
import { Paragraph } from "~/components/ui/typography";
import type { sizes } from "~/components/ui/typography";
import { CtaButtonGroup } from "~/components/ui/button";
import { HostProvider } from "~/components/hero/host-provider";
import type { Logo } from "~/components/logo-list";
import { BlockContent } from "~/components/block-content";
import type {
	CaptionImage,
	SimplePortableText,
	TitleImage,
} from "~/types/sanity-schema";
import { YoutubeWithTracking } from "~/components/youtube-with-tracking";
import type { ImageProps } from "~/components/ui/image";
import { Image } from "~/components/ui/image";
import type { ImageItem } from "~/components/image";
import Carousel from "~/components/carousel/carousel";
import React from "react";
import { Video } from "~/components/ui/video";
import { WistiaWithTracking } from "~/components/wistia-with-tracking";

interface CommonHeroProps {
	breadcrumb?: JSX.Element;
	tagline?: string | React.ReactNode;
	title: string;
	titleSize?: keyof typeof sizes;
	description?: SimplePortableText;
	hostProviders?: Logo[];
	hideHostProviderTitle?: boolean;
	primaryCTA?: Cta;
	secondaryCTA?: Cta;
}
interface CompactHeroProps extends CommonHeroProps {}

function CompactHero({
	breadcrumb,
	tagline,
	title,
	titleSize,
	description,
	hostProviders,
	hideHostProviderTitle,
	primaryCTA,
	secondaryCTA,
}: CompactHeroProps) {
	return (
		<Container className="isolate flex flex-col gap-layout4 lg:flex-row lg:items-center">
			<div className="mx-auto max-w-[1000px] text-center">
				{breadcrumb && <div className="mb-5">{breadcrumb}</div>}
				{tagline ? (
					typeof tagline === "string" ? (
						<Paragraph size="overline" className="mb-5" color="secondary">
							{tagline}
						</Paragraph>
					) : (
						tagline
					)
				) : null}

				{title ? (
					<Paragraph
						as="h1"
						size={titleSize ? titleSize : "heading-1"}
						fontWeight="font-semibold"
					>
						{title}
					</Paragraph>
				) : null}

				{description ? (
					typeof description === "string" ? (
						<Paragraph size="body-large" color="secondary" className="my-6">
							{description}
						</Paragraph>
					) : (
						<BlockContent
							value={description}
							blockOverrides={{
								h1: ({ children }) => (
									<Paragraph
										as="h1"
										size={titleSize ? titleSize : "heading-1"}
										fontWeight="font-semibold"
									>
										{children}
									</Paragraph>
								),
								normal: ({ children }) => (
									<Paragraph
										size="body-large"
										className="mt-6"
										color="secondary"
									>
										{children}
									</Paragraph>
								),
							}}
						/>
					)
				) : null}
				{hostProviders ? (
					<HostProvider
						logoItems={hostProviders}
						variant="compact"
						hideTitle={hideHostProviderTitle}
					/>
				) : null}

				{(primaryCTA && primaryCTA?.url) ||
				(secondaryCTA && secondaryCTA?.url) ? (
					<div className="mt-6 flex flex-col gap-5 md:flex-row md:justify-center">
						<CtaButtonGroup
							primaryCTA={primaryCTA}
							secondaryCTA={secondaryCTA}
						/>
					</div>
				) : null}
			</div>
		</Container>
	);
}

interface DefaultHeroProps extends CommonHeroProps {
	heroHasVideo?: boolean;
	imageSize?: "default" | "small" | "large";
	videoHosting?: "wistia" | "youtube";
	wistiaId?: string;
	ytId?: string;
	image?: ImageProps;
}
function DefaultHero({
	breadcrumb,
	tagline,
	primaryCTA,
	secondaryCTA,
	heroHasVideo = false,
	imageSize = "default",
	title,
	titleSize,
	description,
	hostProviders,
	hideHostProviderTitle,
	ytId,
	videoHosting,
	wistiaId,
	image,
}: DefaultHeroProps) {
	const isImageLarge = imageSize == "large";
	const isImageSmall = imageSize == "small";

	return (
		<Container className="isolate flex flex-col gap-layout4 lg:flex-row lg:items-center">
			<div
				className={clsx("text-center lg:w-1/2 lg:text-left", {
					"lg:w-2/3":
						(!heroHasVideo && image?.src && isImageSmall) || !image?.src,
				})}
			>
				{breadcrumb && <div className="mb-5">{breadcrumb}</div>}
				{tagline ? (
					typeof tagline === "string" ? (
						<Paragraph size="overline" className="mb-5" color="secondary">
							{tagline}
						</Paragraph>
					) : (
						tagline
					)
				) : null}

				{title ? (
					<Paragraph
						as="h1"
						size={titleSize ? titleSize : "heading-1"}
						fontWeight="font-semibold"
					>
						{title}
					</Paragraph>
				) : null}

				{description ? (
					typeof description === "string" ? (
						<Paragraph size="body-large" color="secondary" className="my-6">
							{description}
						</Paragraph>
					) : (
						<BlockContent
							value={description}
							blockOverrides={{
								h1: ({ children }) => (
									<Paragraph
										as="h1"
										size={titleSize ? titleSize : "heading-1"}
										fontWeight="font-semibold"
									>
										{children}
									</Paragraph>
								),
								normal: ({ children }) => (
									<Paragraph
										size="body-large"
										className="mt-6"
										color="secondary"
									>
										{children}
									</Paragraph>
								),
							}}
						/>
					)
				) : null}
				{hostProviders ? (
					<HostProvider
						logoItems={hostProviders}
						variant="default"
						hideTitle={hideHostProviderTitle}
					/>
				) : null}

				{(primaryCTA && primaryCTA?.url) ||
				(secondaryCTA && secondaryCTA?.url) ? (
					<div className="mt-6 flex flex-col gap-5 md:flex-row md:justify-center lg:justify-start">
						<CtaButtonGroup
							primaryCTA={primaryCTA}
							secondaryCTA={secondaryCTA}
						/>
					</div>
				) : null}
			</div>
			{image?.src || (heroHasVideo && (ytId || wistiaId)) ? (
				<div
					className={clsx("mx-auto lg:w-1/2 lg:place-content-center", {
						"max-w-full overflow-visible 2xl:mr-[-124px] 2xl:w-[calc(50%+100px)] min-[1840px]:mr-[-224px] min-[1840px]:w-[calc(50%+200px)]":
							!heroHasVideo && isImageLarge,
						"lg:w-1/3": !heroHasVideo && isImageSmall,
					})}
				>
					{heroHasVideo ? (
						videoHosting === "wistia" && wistiaId ? (
							<WistiaWithTracking wistiaId={wistiaId} />
						) : videoHosting === "youtube" && ytId ? (
							<YoutubeWithTracking id={ytId} loading="eager" />
						) : null
					) : image?.src ? (
						<Image
							className={clsx("mx-auto my-0 mb-6 lg:mb-0", {
								"sm:max-w-[400px]": isImageSmall,
							})}
							loading="eager"
							fetchpriority="high"
							{...image}
						/>
					) : null}
				</div>
			) : null}
		</Container>
	);
}

interface CarouselHeroProps extends CommonHeroProps {
	carouselItems?: CaptionImage[];
	carouselSyncTitleItems?: TitleImage[];
}

function CarouselHero({
	breadcrumb,
	tagline,
	title,
	description,
	hostProviders,
	hideHostProviderTitle,
	primaryCTA,
	secondaryCTA,
	carouselItems,
}: CarouselHeroProps) {
	return (
		<Container className="isolate flex flex-col gap-layout4 lg:flex-row lg:items-center">
			<div className="text-center lg:w-1/2 lg:text-left">
				{breadcrumb && <div className="mb-5">{breadcrumb}</div>}
				{tagline ? (
					typeof tagline === "string" ? (
						<Paragraph size="overline" className="mb-5" color="secondary">
							{tagline}
						</Paragraph>
					) : (
						tagline
					)
				) : null}

				{title ? (
					<Paragraph as="h1" size="heading-1" fontWeight="font-semibold">
						{title}
					</Paragraph>
				) : null}

				{description ? (
					typeof description === "string" ? (
						<Paragraph size="body-large" color="secondary" className="my-6">
							{description}
						</Paragraph>
					) : (
						<BlockContent
							value={description}
							blockOverrides={{
								h1: ({ children }) => (
									<Paragraph
										as="h1"
										size="heading-1"
										fontWeight="font-semibold"
									>
										{children}
									</Paragraph>
								),
								normal: ({ children }) => (
									<Paragraph
										size="body-large"
										className="mt-6"
										color="secondary"
									>
										{children}
									</Paragraph>
								),
							}}
						/>
					)
				) : null}
				{hostProviders ? (
					<HostProvider
						logoItems={hostProviders}
						variant="default"
						hideTitle={hideHostProviderTitle}
					/>
				) : null}

				{(primaryCTA && primaryCTA?.url) ||
				(secondaryCTA && secondaryCTA?.url) ? (
					<div className="mt-6 flex flex-col gap-5 md:flex-row md:justify-center lg:justify-start">
						<CtaButtonGroup
							primaryCTA={primaryCTA}
							secondaryCTA={secondaryCTA}
						/>
					</div>
				) : null}
			</div>
			{
				<div className="mx-auto max-w-full overflow-visible lg:w-1/2 lg:place-content-center 2xl:mr-[-124px] 2xl:w-[calc(50%+100px)] min-[1840px]:mr-[-224px] min-[1840px]:w-[calc(50%+200px)]">
					{carouselItems ? (
						<div
							className="rounded-lg p-layout2 lg:p-layout4"
							style={{
								backgroundColor: "rgba(227, 233, 255, 0.95)",
							}}
						>
							<Carousel
								autoplay={true}
								autoplaySpeed={5000}
								adaptiveHeight={true}
								arrows={false}
								fade={true}
								dotsClass={"carousel-dot-container left flex"}
								appendDots={(dots) => <ul>{dots}</ul>}
							>
								{carouselItems.map((item, i) => {
									const { image } = item;
									const sliderImg = image as ImageItem;
									return (
										<div key={i}>
											<Image
												className="mb-5 rounded-lg"
												loading={i === 0 ? "eager" : "lazy"}
												fetchpriority={i === 0 ? "high" : undefined}
												{...sliderImg}
											/>
											<div className="lg:max-w-content">
												<BlockContent
													value={item.caption}
													headingClassName="!text-grey-100"
													paragraphClassName="!text-grey-80"
												/>
											</div>
										</div>
									);
								})}
							</Carousel>
						</div>
					) : null}
				</div>
			}
		</Container>
	);
}

function CarouselSyncTitle({
	breadcrumb,
	tagline,
	title,
	hostProviders,
	hideHostProviderTitle,
	primaryCTA,
	secondaryCTA,
	carouselSyncTitleItems,
}: CarouselHeroProps) {
	const [activeSlide, setActiveSlide] = React.useState(0);

	function renderTitle() {
		const splittedTitle = title.split("{slideTitle}");

		if (!title) return null;
		if (!carouselSyncTitleItems)
			return (
				<Paragraph as="h1" size="heading-1" fontWeight="font-semibold">
					{title}
				</Paragraph>
			);
		if (splittedTitle.length === 1) {
			return (
				<Paragraph
					as="h1"
					size="heading-1-article"
					fontWeight="font-semibold"
					color="primary"
				>
					{title}

					<span
						key={activeSlide}
						className={clsx("block animate-[fade_1.5s_ease-in-out]")}
					>
						<span className="inline-block !text-primary-80">
							{carouselSyncTitleItems[activeSlide].title}
						</span>
					</span>
				</Paragraph>
			);
		} else {
			return (
				<Paragraph
					as="h1"
					size="heading-1"
					fontWeight="font-semibold"
					color="primary"
				>
					{splittedTitle[0]}

					<span
						key={activeSlide}
						className={clsx("block animate-[fade_1.5s_ease-in-out]")}
					>
						<span className="inline-block !text-primary-80">
							{carouselSyncTitleItems[activeSlide].title}
						</span>
					</span>
					{splittedTitle[1]}
				</Paragraph>
			);
		}
	}
	return (
		<Container className="isolate flex flex-col gap-layout3 lg:flex-row lg:items-center lg:px-layout3">
			<div className="text-center lg:w-[58%] lg:text-left">
				{breadcrumb && <div className="mb-5">{breadcrumb}</div>}
				{tagline ? (
					typeof tagline === "string" ? (
						<Paragraph size="overline" className="mb-5" color="secondary">
							{tagline}
						</Paragraph>
					) : (
						tagline
					)
				) : null}
				{renderTitle()}

				{hostProviders ? (
					<HostProvider
						className="hidden lg:visible"
						logoItems={hostProviders}
						variant="default"
						hideTitle={hideHostProviderTitle}
					/>
				) : null}

				{(primaryCTA && primaryCTA?.url) ||
				(secondaryCTA && secondaryCTA?.url) ? (
					<div className="mt-6 hidden flex-col gap-5 md:flex-row md:justify-center lg:flex lg:justify-start">
						<CtaButtonGroup
							primaryCTA={primaryCTA}
							secondaryCTA={secondaryCTA}
						/>
					</div>
				) : null}
			</div>

			<div className="mx-auto max-w-full overflow-visible lg:w-[calc(42%-24px)] lg:place-content-center 2xl:mr-[-100px] 2xl:w-[calc(42%+80px)] min-[1840px]:mr-[-224px] min-[1840px]:w-[calc(42%+160px)]">
				{carouselSyncTitleItems ? (
					<div>
						<Carousel
							autoplay={true}
							autoplaySpeed={3000}
							adaptiveHeight={true}
							arrows={false}
							dots={false}
							cssEase="linear"
							waitForAnimate={true}
							fade={true}
							infinite={true}
							beforeChange={(current, next) => {
								setActiveSlide(next);
							}}
						>
							{carouselSyncTitleItems.map((item, i) => {
								const { image } = item;
								const sliderImg = image as ImageItem;

								return (
									//ReactFragment removes inline style width: 100%; display: inline-block;
									<React.Fragment key={i}>
										<div
											className={clsx(
												"flex h-full w-full max-w-full items-center justify-center text-center",
												{
													"animate-[fade_1.5s_ease-in-out]": i == activeSlide,
												}
											)}
										>
											<Image
												className="max-w-full"
												loading={i === 0 ? "eager" : "lazy"}
												fetchpriority={i === 0 ? "high" : undefined}
												{...sliderImg}
											/>
										</div>
									</React.Fragment>
								);
							})}
						</Carousel>
					</div>
				) : null}
			</div>
			{hostProviders ? (
				<div className="visible lg:hidden">
					<HostProvider
						className="!flex-row !gap-7"
						logoItems={hostProviders}
						variant="default"
						hideTitle={hideHostProviderTitle}
					/>
				</div>
			) : null}
			{(primaryCTA && primaryCTA?.url) ||
			(secondaryCTA && secondaryCTA?.url) ? (
				<div className="visible my-6 flex flex-col gap-5 md:flex-row md:justify-center lg:hidden lg:justify-start">
					<CtaButtonGroup primaryCTA={primaryCTA} secondaryCTA={secondaryCTA} />
				</div>
			) : null}
		</Container>
	);
}
interface HeroProps {
	tagline?: string | React.ReactNode;
	title: string;
	description?: SimplePortableText;
	titleSize?: keyof typeof sizes;
	image?: ImageProps;
	videoHosting?: "wistia" | "youtube";
	wistiaId?: string;
	ytId?: string;
	primaryCTA?: Cta;
	secondaryCTA?: Cta;
	variant?:
		| "default"
		| "compact"
		| "carousel"
		| "carouselSyncTitle"
		| "bgVideo";
	imageSize?: "default" | "small" | "large";
	isDarkTheme?: boolean;
	backgroundColor?: string;
	backgroundImage?: string;
	backgroundPosition?: string;
	backgroundSize?: string;
	backgroundVideo?: string;
	hostProviders?: Logo[];
	hideHostProviderTitle?: boolean;
	breadcrumb?: JSX.Element;
	heroHasVideo?: boolean;
	roundedCorners?: string;
	carouselItems?: CaptionImage[];
	carouselSyncTitleItems?: TitleImage[];
}

interface BgVideoHeroProps extends CommonHeroProps {
	backgroundVideo?: string;
}
function BgVideoHero({
	breadcrumb,
	tagline,
	title,
	titleSize,
	description,
	hostProviders,
	hideHostProviderTitle,
	primaryCTA,
	secondaryCTA,
	backgroundVideo,
}: BgVideoHeroProps) {
	return (
		<div className="relative bg-black">
			<Video
				src={backgroundVideo}
				controls={false}
				loop
				muted
				autoPlay
				preload="auto"
				className="absolute inset-0 h-full w-full object-cover"
			/>
			<div className="absolute inset-0 bg-black opacity-50"></div>
			<Container className="isolate flex min-h-[600px] flex-col justify-center gap-layout4 lg:flex-row lg:items-center">
				<div className="dark mx-auto max-w-[1000px] text-center drop-shadow-lg">
					{breadcrumb && <div className="mb-5">{breadcrumb}</div>}
					{tagline ? (
						typeof tagline === "string" ? (
							<Paragraph size="overline" className="mb-5" color="secondary">
								{tagline}
							</Paragraph>
						) : (
							tagline
						)
					) : null}

					{title ? (
						<Paragraph
							as="h1"
							size={titleSize ? titleSize : "heading-1"}
							fontWeight="font-semibold"
						>
							{title}
						</Paragraph>
					) : null}

					{description ? (
						typeof description === "string" ? (
							<Paragraph size="body-large" color="secondary" className="my-6">
								{description}
							</Paragraph>
						) : (
							<BlockContent
								value={description}
								blockOverrides={{
									h1: ({ children }) => (
										<Paragraph
											as="h1"
											size={titleSize ? titleSize : "heading-1"}
											fontWeight="font-semibold"
										>
											{children}
										</Paragraph>
									),
									normal: ({ children }) => (
										<Paragraph
											size="body-large"
											className="mt-6"
											color="secondary"
										>
											{children}
										</Paragraph>
									),
								}}
							/>
						)
					) : null}
					{hostProviders ? (
						<HostProvider
							logoItems={hostProviders}
							variant="compact"
							hideTitle={hideHostProviderTitle}
						/>
					) : null}

					{(primaryCTA && primaryCTA?.url) ||
					(secondaryCTA && secondaryCTA?.url) ? (
						<div className="mt-6 flex flex-col gap-5 md:flex-row md:justify-center">
							<CtaButtonGroup
								primaryCTA={primaryCTA}
								secondaryCTA={secondaryCTA}
							/>
						</div>
					) : null}
				</div>
			</Container>
		</div>
	);
}

export function Hero({
	tagline,
	title,
	titleSize = "heading-1",
	description,
	image,
	videoHosting,
	wistiaId,
	ytId,
	primaryCTA,
	secondaryCTA,
	variant = "default",
	backgroundColor,
	backgroundImage,
	backgroundPosition,
	backgroundSize,
	backgroundVideo,
	hostProviders,
	hideHostProviderTitle,
	breadcrumb,
	imageSize = "default",
	heroHasVideo = false,
	roundedCorners,
	carouselItems,
	carouselSyncTitleItems,
}: HeroProps) {
	return (
		<header
			className={clsx(
				"overflow-hidden bg-theme-secondary bg-no-repeat",
				roundedCorners
			)}
			style={{
				backgroundColor: backgroundColor ?? undefined,
				backgroundImage: backgroundImage ?? undefined,
				backgroundPosition: backgroundPosition ?? undefined,
				backgroundSize: backgroundSize ?? undefined,
			}}
		>
			{variant === "compact" ? (
				<CompactHero
					breadcrumb={breadcrumb}
					tagline={tagline}
					title={title}
					titleSize={titleSize}
					description={description}
					hostProviders={hostProviders}
					hideHostProviderTitle={hideHostProviderTitle}
					primaryCTA={primaryCTA}
					secondaryCTA={secondaryCTA}
				/>
			) : null}
			{variant === "default" ? (
				<DefaultHero
					breadcrumb={breadcrumb}
					tagline={tagline}
					title={title}
					titleSize={titleSize}
					description={description}
					hostProviders={hostProviders}
					hideHostProviderTitle={hideHostProviderTitle}
					primaryCTA={primaryCTA}
					secondaryCTA={secondaryCTA}
					heroHasVideo={heroHasVideo}
					imageSize={imageSize}
					videoHosting={videoHosting}
					wistiaId={wistiaId}
					ytId={ytId}
					image={image}
				/>
			) : null}
			{variant === "carousel" ? (
				<CarouselHero
					breadcrumb={breadcrumb}
					tagline={tagline}
					title={title}
					description={description}
					hostProviders={hostProviders}
					hideHostProviderTitle={hideHostProviderTitle}
					primaryCTA={primaryCTA}
					secondaryCTA={secondaryCTA}
					carouselItems={carouselItems}
				/>
			) : null}
			{variant == "carouselSyncTitle" ? (
				<CarouselSyncTitle
					breadcrumb={breadcrumb}
					tagline={tagline}
					title={title}
					hostProviders={hostProviders}
					hideHostProviderTitle={hideHostProviderTitle}
					primaryCTA={primaryCTA}
					secondaryCTA={secondaryCTA}
					carouselSyncTitleItems={carouselSyncTitleItems}
				/>
			) : null}
			{variant === "bgVideo" ? (
				<BgVideoHero
					breadcrumb={breadcrumb}
					tagline={tagline}
					title={title}
					titleSize={titleSize}
					description={description}
					hostProviders={hostProviders}
					hideHostProviderTitle={hideHostProviderTitle}
					primaryCTA={primaryCTA}
					secondaryCTA={secondaryCTA}
					backgroundVideo={backgroundVideo}
				/>
			) : null}
		</header>
	);
}
