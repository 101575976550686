import { WistiaPlayer } from "@wistia/react-embeds";

export type WistiaProps = {
	wistiaId: string;
	onReady?: (name: string, duration: number) => void;
	onPlay?: () => void;
	onPause?: () => void;
	onEnd?: () => void;
	onTimeChange?: (currentTime: number) => void;
	onPercentWatchedChange?: (percentWatched: number) => void;
};

export function Wistia({
	wistiaId,
	onReady,
	onPlay,
	onPause,
	onEnd,
	onTimeChange,
	onPercentWatchedChange,
}: WistiaProps) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleReady = (player: any) => {
		const name = player.name();
		const duration = player.duration();
		onReady?.(name, duration);
	};

	return (
		<WistiaPlayer
			hashedId={wistiaId}
			onReady={handleReady}
			onPlay={onPlay}
			onPause={onPause}
			onEnd={onEnd}
			onTimeChange={onTimeChange}
			onPercentWatchedChanged={(percent: number) => {
				const watchedPercentage = Math.floor(percent * 100);
				onPercentWatchedChange?.(watchedPercentage);
			}}
			playerColor="#3545be" // color-secondary-60
			className="overflow-hidden rounded-lg bg-black"
		/>
	);
}
