import { useState } from "react";
import { Wistia } from "~/components/wistia";
import { spTrackVideo } from "~/utils/tracking";

type WistiaWithTrackingProps = {
	wistiaId: string;
};

export function WistiaWithTracking({ wistiaId }: WistiaWithTrackingProps) {
	const [name, setName] = useState<string>("");
	const [duration, setDuration] = useState<number>(0);
	const [currentTime, setCurrentTime] = useState<number>(0);
	const [percentWatched, setPercentWatched] = useState<number>(0);

	const percentagesArr = [25, 50, 75, 100];
	let progressPercentage = 0;
	let i = 0;
	while (percentagesArr[i] <= percentWatched) {
		progressPercentage = percentagesArr[i++];
	}

	const handleWistiaEvent = (state: "playing" | "paused" | "ended") => {
		if (state === "ended") {
			progressPercentage = 100;
		}

		spTrackVideo({
			state: currentTime === 0 ? "start" : state,
			title: name,
			url: `https://fast.wistia.com/embed/medias/${wistiaId}`,
			duration: duration,
			currentTime,
			percentage: progressPercentage,
		});
	};

	const handlePlay = () => handleWistiaEvent("playing");
	const handlePause = () => handleWistiaEvent("paused");
	const handleEnd = () => handleWistiaEvent("ended");

	const handleReady = (videoName: string, videoDuration: number) => {
		setName(videoName);
		setDuration(videoDuration);
	};

	const handlePercentWatchedChange = (watched: number) => {
		setPercentWatched(watched);
	};

	const handleTimeChange = (time: number) => {
		setCurrentTime(time);
	};

	return (
		<Wistia
			wistiaId={wistiaId}
			onPlay={handlePlay}
			onPause={handlePause}
			onEnd={handleEnd}
			onReady={handleReady}
			onPercentWatchedChange={handlePercentWatchedChange}
			onTimeChange={handleTimeChange}
		/>
	);
}
