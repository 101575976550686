import { Paragraph } from "~/components/ui/typography";
import { LogoItem } from "~/components/logo-list";
import type { Logo } from "~/components/logo-list";
import { useSharedContent } from "~/hooks/localization";
import clsx from "clsx";

interface HostProviderProps {
	logoItems: Logo[];
	variant?: "default" | "compact";
	hideTitle?: boolean;
	className?: string;
}

export function HostProvider({
	logoItems,
	variant = "default",
	hideTitle,
	className,
}: HostProviderProps) {
	const { t } = useSharedContent();

	return (
		<>
			{logoItems.length > 0 ? (
				<>
					{!hideTitle ? (
						<Paragraph size="overline" color="tagline" className="mt-6">
							{t("hostProviderTitle")}
						</Paragraph>
					) : null}
					<div
						className={clsx(
							"flex flex-row flex-wrap items-center justify-center gap-6 pt-5",
							{ "lg:justify-center": variant === "compact" },
							{ "lg:justify-start": variant === "default" },
							{ "sm:pt-0": !hideTitle }
						)}
					>
						{logoItems.map((logo, index) => (
							<LogoItem key={index} logo={logo} imageLoading="eager" />
						))}
					</div>
				</>
			) : null}
		</>
	);
}
